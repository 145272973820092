import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, getUserInfo ,setUserInfo} from '../../redux/slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logoIcon from '/assets/images/logoIcon.png';
import './login.css';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, isAuthenticated: userIsAuthenticated, token, errorMessage , userInfo} = useSelector(getUserInfo);
  const [formData, setFormData] = useState({ userName: '', password: '' });
  const [error, setError] = useState('');
  const [inputError, setInputError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userIsAuthenticated && token) {
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(userInfo)); // Save user info to localStorage
      setTimeout(() => {
        navigate('/home');
      }, 2000);
    }
  }, [userIsAuthenticated, token, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setInputError(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setInputError(false);

    if (!formData.userName || !formData.password) {
      setError('Both fields are required');
      setInputError(true);
      return;
    }

    try {
      const response = await dispatch(loginUser(formData)).unwrap();
      dispatch(setUserInfo(response?.userInfo));
    } catch (errorMessage) {
      console.error('Login failed:', error);
      setError(errorMessage);
      setInputError(true);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-full bg-gradient-to-r from-purple-600 to-pink-500">
      <div className="flex flex-col items-center w-full max-w-[21rem] bg-white rounded-lg shadow-lg hover:shadow-lg p-8 shadow-custom border-primary border-[1px]">
        <img src={logoIcon} alt="logo" className="h-36 w-36" />
        <p className="text-sm text-primary font-semibold pb-8 pt-2">Kea Expense</p>
        <div>
          <h2 className="text-xl text-center font-semibold text-[#7a7a7a] pb-5">Login</h2>

          {error && <p className="text-red-500 text-center pb-4">{error.slice(1, -1)}</p>}

          <form onSubmit={handleLogin} className="space-y-8">
            {/* Username Input */}
            <div className="relative mb-5">
              <input
                type="text"
                id="userName"
                name="userName"
                placeholder=" "
                value={formData.userName}
                onChange={handleChange}
                required
                className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 transition-all duration-300 ${inputError ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary'}`}
              />
              <label
                htmlFor="userName"
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-primary-gray transition-all duration-300 origin-left bg-white px-1 pointer-events-none"
              >
                Username
              </label>
            </div>

            {/* Password Input */}
            <div className="relative mb-5">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder=" "
                value={formData.password}
                onChange={handleChange}
                required
                className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 transition-all duration-300 ${inputError ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary'}`}
              />
              <label
                htmlFor="password"
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-primary-gray transition-all duration-300 origin-left bg-white px-1 pointer-events-none"
              >
                Password
              </label>
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash }
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-primary-gray cursor-pointer"
                onClick={() => setShowPassword((prevShow) => !prevShow)}
              />
            </div>

            <button
              className="w-full py-3 bg-primary text-white font-semibold rounded-lg shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary mb-5"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
