import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({
  label = 'Select an option',
  options = [],
  name,
  attributeName = '',
  onChange,
  dropdownHeight = 'h-10',
  dropdownWidth = 'w-44',
  attributeValue = '',
  isDisable = false, // Added isDisable prop
  disabledValue = '',
  value='', // Optional value when disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(label);

  const memoizedOptions = useMemo(() => options, [options]);

  useEffect(() => {
    if (value =='' || value === undefined || value === null) {
      setSelected(label);
    }
  },[value, label]);

  // Assign the disabled value if isDisable is true
  useEffect(() => {
    if (isDisable && disabledValue && selected !== disabledValue) {
      setSelected(disabledValue);
      onChange({ [name]: disabledValue });
    }
  }, [isDisable, disabledValue, selected, name, onChange]);


  const handleToggle = () => {
    if (!isDisable) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = useCallback(
    (option) => {
      if (!isDisable) {
        setSelected(option[attributeName] || option);
        onChange({ [name]: option[attributeValue] || option });
        setIsOpen(false);
      }
    },
    [attributeName, attributeValue, name, onChange, isDisable]
  );

  return (
    <div className={`relative inline-block text-left ${dropdownWidth}`}>
      <button
        type="button"
        onClick={handleToggle}
        disabled={isDisable} // Conditionally disable the dropdown
        className={`block ${dropdownWidth} ${dropdownHeight} px-3 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md  bg-white hover:bg-gray-50 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-left flex justify-between items-center ${
          isDisable ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        <span>{selected}</span>
        {!isDisable && (
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2" />
        )}
      </button>
      {!isDisable && isOpen && (
        <div
          className={`absolute z-10 ${dropdownWidth} mt-1 bg-white border border-gray-300 rounded-md shadow-lg`}
        >
          {Array.isArray(memoizedOptions) && memoizedOptions.length > 0 ? (
            memoizedOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => handleSelect(option)}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-left"
              >
                {attributeName ? option[attributeName] : option}
              </button>
            ))
          ) : (
            <button
              onClick={() => handleSelect(memoizedOptions)}
              className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-left"
            >
              {attributeName ? memoizedOptions[attributeName] : memoizedOptions}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;