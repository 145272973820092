import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';



export const getSfcFromCities = createAsyncThunk('getSfcFromCities', async(id,{ rejectWithValue, dispatch }) => {
  try {
    const res = await axios.get(`/SFCTerritoryCity`, {
      params: { territoryId: id },
    });
    return successResponseParser(res)
   } catch (error) {
    return rejectWithValue(errorResponseParser(error,dispatch))
   }
});

export const getSfcToCities = createAsyncThunk('getSfcToCities', async(id,{ rejectWithValue, dispatch }) => {
  try {
    const res = await axios.get(`/SFCTerritoryCity`, {
      params: { territoryId: id },
    });
    return successResponseParser(res)
   } catch (error) {
    return rejectWithValue(errorResponseParser(error,dispatch))
   }
});



export const fetchSfcFormFilters = createAsyncThunk(
  'fetchSfcFormFilters',
  async (params,{ rejectWithValue, dispatch }) => {
    try {
      const res =  await Promise.all([
        axios.get(`/SFCTerritoryCategory`),
        axios.get(
          `/SFCTerritoryModeOfTransport?gradeId=1&type=expense`
        ),
        axios.get(`/SFCTerritory`, { params: params }),
      ]);
      return res
     } catch (error) {
      return rejectWithValue(errorResponseParser(error,dispatch))
     }
  }
);


export const sfcFormFilters = createSlice({
  name: 'sfcFormFilters',
  initialState: {
    isLoading: false,
    fromCities: [],
    toCities: [],
    territory: [],
    transport: [],
    categories: [],
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFormFilters.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSfcFormFilters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload[0].data;
      state.transport = action.payload[1].data;
      state.territory = action.payload[2].data;
    });
    builder.addCase(fetchSfcFormFilters.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.categories = [];
      state.transport = [];
      state.territory = [];
    });
    builder.addCase(getSfcFromCities.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSfcFromCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fromCities = action.payload;
    });
    builder.addCase(getSfcFromCities.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.fromCities = [];
    });
    //////////////////calling sfc to city////////////
    builder.addCase(getSfcToCities.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSfcToCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.toCities = action.payload;
    });
    builder.addCase(getSfcToCities.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.toCities = [];
    });
  },
});

export default sfcFormFilters.reducer;
