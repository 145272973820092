import React from 'react';

const ErrorsPopup = ({ show, message , onClose}) => {
  return show ? (
    <div className="absolute w-full h-full bg-disable z-30 flex justify-center items-center left-0 top-0">
      <div className="p-5 rounded-md bg-white w-[90%] flex flex-col justify-center items-center gap-5 py-5">
        <p className='text-center'>
         {message}
        </p>
        <button className="py-2 w-1/4 bg-primary text-white rounded-full p-3" onClick={onClose}>Ok</button>
      </div>
    </div>
  ) : null;
};

export default ErrorsPopup;
