import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logoIcon from '../../../public/assets/images/logoIcon.png'

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="bg-white absolute left-0 top-0  w-screen h-screen flex flex-col gap-1 justify-center items-center">
      <div className='flex flex-col justify-center items-center gap-3 text-base font-semibold text-primary'>
      <img src={logoIcon} alt="logo" className="h-32 w-32" />
      <div className=''>Kea Expense</div>
      </div>
    </div>
  );
};

export default SplashScreen;
