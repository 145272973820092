import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Plane, ViceversaArrow } from './compoments/Icons';
import {
  faCircleInfo,
  faMotorcycle,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { sfcTravelExpenceDelete } from '../../redux/slice/sfcTravelExpenseDelete';
import { useDispatch } from 'react-redux';
import { upadateExpenseIdCreationFormDataForEdit } from '../../redux/slice/sfcTravelExpenseForm';
import CarIcon from '../../assets/icons/car.png';
import BusIcon from '../../assets/icons/bus.png';
import FlightIcon from '../../assets/icons/flight.png';
import TrainIcon from '../../../public/assets/images/trainIcon.png';
import ErrorsPopup from './ErrorsPopup';
import ConfirmationPopup from './ConfirmationPopup';

const getTransportTypeIcons = (id) => {
  switch (id) {
    case 1:
      return (
        <FontAwesomeIcon
          icon={faMotorcycle}
          className="text-primary"
          width={'20px'}
          height={'20px'}
        />
      );
    case 2:
      return (
        <img src={TrainIcon} alt="train-icon" width={'20px'} height={'20px'} />
      );
    case 3:
      return (
        <img
          src={BusIcon}
          alt="bus-train-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    case 4:
    case 6:
      return (
        <img src={CarIcon} alt="car-taxi-icon" width={'20px'} height={'20px'} />
      );
    case 5:
      return (
        <img
          src={FlightIcon}
          alt="flight-icon"
          width={'20px'}
          height={'20px'}
        />
      );
    default:
      return null;
  }
};

const CardContainer = ({
  status,
  val,
  onEdit,
  display,
  setDetailedViewId,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [showRemarks, setShowRemarks] = useState(false);

  return (
    <>
      <ErrorsPopup
        show={showRemarks}
        onClose={() => setShowRemarks(false)}
        message={val?.reason}
      />
      <div
        className="p-4 bg-white overflow-hidden flex flex-col gap-5 min-h-[120px] box-border"
        onClick={() => {
          // setDisplay((prev) => !prev)
          display
            ? setDetailedViewId(null)
            : setDetailedViewId(val.stpfcDetailid);
        }}
      >
        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <div className="flex gap-4 items-center">
              <div className="text-xs">
                {getTransportTypeIcons(val?.transportId)}
              </div>
              <div className="text-secondary text-[10px]">
                {val.transportName}
              </div>
              <div className="text-secondary text-[10px]">
                {val?.categoryName}
              </div>
            </div>

            <p
              className={`${
                status === 'A' || status === 'S'
                  ? 'text-primary'
                  : status === 'R'
                  ? 'text-red-500'
                  : 'text-draft-bg-color'
              }  py-0.5 rounded font-medium flex items-center gap-2 text-[10px]`}
            >
              <span>
                {status === 'A'
                  ? 'Approved'
                  : status === 'D'
                  ? 'Draft'
                  : status === 'S'
                  ? 'Submitted'
                  : status === 'R'
                  ? 'Rejected'
                  : null}
              </span>
              {status === 'A' || status === 'R' ? (
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="text-primary"
                  // width={'20px'}
                  // height={'20px'}
                  size='sm'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRemarks(true);
                  }}
                />
              ) : null}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex w-3/4 md:gap-40 my-lg:gap-7 items-center">
              <p className="w-1/2 text-ellipsis overflow-hidden text-sm font-medium">
                {val?.fromCityName}
              </p>
              <div>
                <ViceversaArrow color="#79747E" />
              </div>
              <p className="w-1/2 text-ellipsis overflow-hidden text-sm font-medium">
                {val?.toCityName}
              </p>
            </div>
            <div className="text-left text-sm">
              <span className="">₹ </span>
              {val?.total}
            </div>
          </div>
          {display ? (
            <div className="flex justify-between items-center text-secondary text-[10px]">
              <p>Effective Date</p>
              <p>{val?.effectiveDate}</p>
            </div>
          ) : null}
          {display ? (
            <div className="w-screen h-[0.5px] bg-line-color mt-4 translate-x-[-16px]"></div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={`${display ? 'show' : 'hidden'} flex flex-col gap-5`}>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>Category</div>
            <div>{val?.categoryName}</div>
          </div>
          <div className="w-full h-[0.5px] bg-line-color"></div>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>One-way Distance</div>
            <div>
              {val?.oneWayDistance} <span>KM</span>
            </div>
          </div>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>Two-way Distance</div>
            <div>
              {val?.twoWayDistance} <span>KM</span>
            </div>
          </div>
          <div className="w-1/1 h-[0.5px] bg-line-color "></div>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>One-way Fare</div>
            <div>₹ {val?.oneWayFare}</div>
          </div>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>Two-way Fare</div>
            <div>₹ {val?.twoWayFare}</div>
          </div>
          <div className="flex justify-between text-secondary-400 text-xs font-medium">
            <div>Allowance</div>
            <div>₹ {val?.allowance}</div>
          </div>
          <div className="flex justify-between bold text-secondary-400 text-sm font-semibold">
            <div>Total</div>
            <div>₹ {val?.total}</div>
          </div>
          {status === 'D' ? (
            <div className="w-1/1 h-[0.5px] bg-line-color mt-2 "></div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex justify-between ">
          {status === 'D' || status === 'R' ? (
            <div>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit();
                  dispatch(upadateExpenseIdCreationFormDataForEdit(val));
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="text-primary"
                />
              </button>
            </div>
          ) : (
            <div></div>
          )}
          {status === 'D' ? (
            <div>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete({
                    stpfcId: val?.stpfcId,
                    stpfcDetailid: val?.stpfcDetailid,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="text-primary" />
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardContainer;
