import React from 'react';

const Btn = ({ isSelected = true, index, text, onClick }) => {
  return (
    <button
      className={`py-1 px-4 border border-line-color rounded-full bg-${
        isSelected ? 'primary' : 'white'
      } text-${isSelected ? 'white' : 'black'} whitespace-nowrap text-sm`}
      onClick={onClick}
    >
      <p>{text}</p>
    </button>
  );
};

export default Btn;
