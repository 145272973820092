import React, { useEffect, useState, useRef } from 'react';
import MyExpenseList from './MyExpenseList';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import PopupModal from '../../components/modal/PopupModal.jsx';
import Loader from '../../components/modal/Loader.jsx';
import {
  fetchMyExpenseData,
  fetchExpenseCityFilter,
  setExpenseFinalSubmit,
  getFinalSubmitExpense,
  getMyExpensedata,
} from '../../redux/slice/myExpenseSlice';

//image
import leftArrowIcon from '../../../public/assets/images/leftArrowIcon.png';

const MyExpense = () => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    isLoading,
    apiError,
    errorMessage,
    myExpenseData,
    expenseCityFilter,
  } = useSelector(getMyExpensedata);
  const finalSubmitExpense = useSelector(getFinalSubmitExpense);

  useEffect(() => {
    dispatch(fetchMyExpenseData());
    dispatch(fetchExpenseCityFilter());
  }, [dispatch]);

  const handleFilter = (filter) => {
    setSelectedFilter((prevFilter) => (prevFilter === filter ? '' : filter));
  };

  const filteredExpenses = selectedFilter
    ? myExpenseData.filter(
        (item) =>
          item.status === selectedFilter ||
          item.fromCity === selectedFilter ||
          item.toCity === selectedFilter
      )
    : myExpenseData;

  const handlerExpenseSubmit = () => {
    setModalOpen(true);
    dispatch(setExpenseFinalSubmit());
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const filteredCityStatusName = [
    'Unsubmitted',
    'Submitted',
    ...expenseCityFilter?.map((item) => item.cityName),
  ];

  return (
    <div className="w-screen flex flex-col">
      {isLoading ? (
        <Loader />
      ) : apiError ? (
        //have to get resposne from api and assign to apiError dynamic error message
        <PopupModal message={errorMessage} type="Fail" isOpen={true} />
      ) : (
        <>
          {/* Header */}
          <div className="flex justify-center items-center w-full h-10 relative p-4 mt-6 mb-2">
            <Link to="/home" className="absolute left-4">
              <img
                src={leftArrowIcon}
                alt="leftIcon"
                height={'10px'}
                width={'10px'}
              />
            </Link>
            <p className="text-baselg font-bold text-center text-primary">
              My Expense
            </p>
          </div>

          {/* Filter Buttons */}
          <div className="p-3 flex gap-6 items-center mb-2 max-w-full">
            <div className="grow overflow-scroll no-scroll">
              <div className="flex items-center gap-3">
                {filteredCityStatusName &&
                  filteredCityStatusName?.length > 0 &&
                  filteredCityStatusName?.map((data, index) => (
                    <button
                      key={index}
                      className={`rounded-3xl px-4 py-2 border-[1px] border-line-color text-center whitespace-nowrap ${
                        selectedFilter === data
                          ? 'bg-primary text-white border-primary'
                          : 'bg-white'
                      } transition-all duration-200`}
                      onClick={() => handleFilter(data)}
                    >
                      <p className="text-baseMd font-medium">{data}</p>
                    </button>
                  ))}
              </div>
            </div>
          </div>

          <div className="divider-y h-[1px] w-full bg-line-color mt-2"></div>

          {/* Expense List or No Data */}
          {filteredExpenses && filteredExpenses?.length === 0 ? (
            <div className="flex items-center justify-center mt-56">
              <h3
                className="text-center mt-20 font-bold"
                style={{ color: '#A04D95' }}
              >
                No Data Found
              </h3>
            </div>
          ) : (
            <div className="flex-1 overflow-y-auto mt-2">
              {Array.isArray(filteredExpenses) &&
                filteredExpenses?.length > 0 &&
                filteredExpenses?.map((item, index) => (
                  <div key={index}>
                    <MyExpenseList id={index} item={item} isMyExpense={true} />
                    <div className="divider-y h-[1px] w-full bg-line-color"></div>
                  </div>
                ))}
              <div className="mb-28"></div>
            </div>
          )}

          {/* Submit Button */}
          <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[7rem]  w-full flex justify-around items-center gap-3 p-4">
            <Button
              btFullwidth={true}
              label="Submit"
              btBgColor="bg-primary"
              btTextColor="text-white"
              onClick={handlerExpenseSubmit}
            />
          </div>

          {/* PopupModal */}
          {finalSubmitExpense && (
            <PopupModal
              time={1000}
              isOpen={modalOpen}
              message={finalSubmitExpense?.message}
              type={finalSubmitExpense?.status}
              onClose={closeModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MyExpense;
