import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';




export const fetchSfcTravelingExpences = createAsyncThunk(
  'fetchSfcTravelingExpences',
  async (_,{rejectWithValue,dispatch}) => {
   try {
    const employeeId = JSON.parse(localStorage.getItem("userData"))?.employeeId
    const res = await axios.get(`/SFCBindGrid`, {
      params: { EmployeeId: employeeId },
    });
    return successResponseParser(res)
   } catch (error) {
    return rejectWithValue(errorResponseParser(error,dispatch))
   }
  }
);

export const sfcTravelingExpences = createSlice({
  name: 'sfcTravelingExpences',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcTravelingExpences.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSfcTravelingExpences.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload
    });
    builder.addCase(fetchSfcTravelingExpences.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcTravelingExpences.reducer;
