import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/modal/Loader';
import {
  getAllUnreportedExpense,
  fetchUnReportedData,
  setSelectedAttachment,
} from '../../redux/slice/unReportedSlice';
import SideBar from '../../components/sidebar/SideBar';

const UnReportedExpenses = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { unReportedData, isLoading, apiError } = useSelector(
    getAllUnreportedExpense
  );

  useEffect(() => {
      dispatch(fetchUnReportedData());
  }, [dispatch]);

  const handleAttachment = (invoice) => {
    if (state) {
      const selectedExpenseData = localStorage.getItem('SelectedExpenseData');
      if (selectedExpenseData) {
        const getDayWiseData = JSON.parse(selectedExpenseData);
        const { activityDate, expenseDetailId, expenseId } = getDayWiseData;
        const {
          employeeId,
          expenseType,
          amount,
          description,
          unReportExpenseId,
          expenseOrSundry,
        } = invoice;

        const attachmentData = {
          activityDate,
          expenseDetailId,
          expenseId,
          employeeId,
          expenseType,
          fare: amount,
          description,
          unReportExpenseId,
          travelMode: 0,
          expenseOrSundry,
        };
        dispatch(setSelectedAttachment(attachmentData));
        navigate('/my-expense-details');
      }
    }
  };

  return (
    <div className="p-4 relative w-screen h-screen">
      {isLoading && <Loader />}
      {apiError && (
        <h1 className="text-center text-semibold text-red-500">{apiError}</h1>
      )}
      {!isLoading && !apiError && (
        <>
          <div className="flex items-center mb-2">
            <SideBar />
            <p className="text-baselg font-bold text-center text-primary pl-20">
              Unreported Bills
            </p>
          </div>

          <div className="w-full h-[1px] bg-line-color"></div>

          {unReportedData && unReportedData.length > 0 ? (
            unReportedData.map((invoice, index) => (
              <div key={index}>
                <div
                  key={invoice?.unReportExpenseId}
                  className="rounded-md flex justify-between items-center w-full"
                  onClick={() => handleAttachment(invoice)}
                >
                  <img
                    src={`data:image/jpg;base64,${invoice?.imageBase64}`}
                    alt="Unrecognized"
                    className="w-[79px] h-[79px] p-2 object-cover rounded-xl"
                  />
                  <div className="w-full p-4">
                    <div className="flex flex-row justify-between items-center">
                      <p className="text-baseXSm text-lightGray">
                        {invoice.invoiceDate.slice(0, 10)}
                      </p>
                      <p className="text-primary rounded-lg text-baseSm font-semibold">
                        {invoice?.status || 'unsubmited'}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="text-baseMd font-medium text-[#49454F]">
                        {invoice?.invoiceNumber?.length > 10
                          ? `${invoice.invoiceNumber.slice(0, 10)}...`
                          : invoice.invoiceNumber || 'N/A'}
                      </p>
                      <p className="font-semibold text-base text-[#514D4C]">
                        INR {invoice?.amount || '0.00'}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="text-baseXSm text-lightGray">
                        {invoice.expenseType?.length > 8
                          ? `${invoice?.expenseType.slice(0, 10)}...`
                          : invoice.expenseType || 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-line-color"></div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center pt-72">
              <h3 className="text-center font-bold text-primary text-lg">
                No Unreported Bills
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UnReportedExpenses;
