import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//images
import bikeIcon from '/assets/images/bikeIcon.png';
import carIcon from '/assets/images/carIcon.png';
import planeIcon from '/assets/images/flightIcon.png';
import trainIcon from '/assets/images/trainIcon.png';
import BusIcon from '/assets/images/busIcon.png';
import taxiIcon from '/assets/images/taxiIcon.png';
import swapIcon from '/assets/images/swapIcon.png';
const MyExpenseList = ({ id = '332312', item, isMyExpense = false }) => {
  const [expenseDataShow, setExpenseDataShow] = useState(null);
  const [isEpanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    // expenseDetailId,
    fromCity,
    toCity,
    total,
    status,
    activity,
    activityDate,
    category,
    modeOfTransport,
    fare = 0,
    categoryName = 'Meeting',
    isModifyRoute = false,
  } = item;

  const handlerSetData = (data) => {
    if (data) {
      const { activityDateFormat, expenseId, expenseDetailId } = data;
      localStorage.setItem(
        'SelectedExpenseData',
        JSON.stringify({
          activityDate: activityDateFormat,
          expenseId,
          expenseDetailId,
        })
      );
      // dispatch(setSelectedExpense(data));
      navigate('/my-expense-details');
    }
  };

  const handlerClicked = (data) => {
    isMyExpense && handlerSetData(data);
    if (!isMyExpense) {
      setExpanded(!isEpanded);
      setExpenseDataShow(data);
    }
  };

  const dividerClass = 'divider-y h-[1px] w-full bg-line-color';

  const transportIcons = {
    '2 Wheeler': bikeIcon,
    Train: trainIcon,
    Bus: BusIcon,
    Flight: planeIcon,
    Taxi: taxiIcon,
    default: carIcon,
  };

  const getTransportTypeIcons = (transport) => {
    const icon = transportIcons[transport] || transportIcons.default;
    return <img src={icon} alt={`${transport} icon`} width="16px" height="16px" />;
  };


  return (
    <div
      className={`w-full rounded-md p-4 ${
        isModifyRoute ? 'bg-green-200' : 'bg-white'
      }`}
      key={id}
      onClick={() => handlerClicked(item)}
    >
      <div className="w-full flex justify-between align-items-center">
        <div className="p-1">
          <p className="flex flex-row items-center gap-4">
            {getTransportTypeIcons(modeOfTransport)}
            <span className="text-lightGray text-baseXSm font-medium ">
              {modeOfTransport}
            </span>{' '}
            <span className="text-lightGray text-baseXSm font-medium">
              {category}
            </span>
          </p>
        </div>
        <div>
          <p className="text-primary text-baseXSm font-semibold">
            {status || ''}
          </p>
        </div>
      </div>
      <div className="w-full h-10 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <p
            className="text-base text-primary-black"
            style={{ minWidth: '100px'}}
          >
            {fromCity?.length > 10 ? fromCity.slice(0, 10) + '...' : fromCity}
          </p>
          <div className="flex items-center justify-center">
            <img src={swapIcon} alt="swap icon" className="h-5 w-5" />
          </div>
          <p
            className="text-base text-primary-black pl-3"
            style={{ minWidth: '100px'}}
          >
            {toCity?.length > 10 ? toCity?.slice(0, 10) + '...' : toCity}
          </p>
        </div>
        <div>
          <p className="text-base font-medium">₹ {total || fare}</p>
        </div>
      </div>
      <div className="w-full h-6 flex justify-between">
        <div>
          <p className="text-lightGray text-baseSm font-medium">
            {activity || categoryName}
          </p>
        </div>
        <div>
          <p style={{ color: '#2FC23D' }} className="text-baseSm font-medium">
            {activityDate}
          </p>
        </div>
      </div>

      {isEpanded && <div className={dividerClass}></div>}
      {/* Expand Data Section */}
      {isEpanded && expenseDataShow && (
        <div>
          <div className="flex flex-row w-full justify-between mt-2 mb-2">
            <p className='text-baseSm font-medium text-[#514D4C]'>Category</p>
            <p className='text-baseSm font-medium text-[#514D4C]'>{expenseDataShow.category || 'N/A'}</p>
          </div>
          <div className={dividerClass}></div>
          <div className="flex justify-between flex-col mt-2 mb-2">
            <div className="flex flex-row w-full justify-between">
              <p className='text-baseSm font-medium text-[#514D4C]'>Travel Mode</p>
              <p className='text-baseSm font-medium text-[#514D4C]'>{expenseDataShow.travelMode || 'N/A'}</p>
            </div>
            <div className="flex flex-row w-full justify-between mt-2">
              <p className='text-baseSm font-medium text-[#514D4C]'>Distance</p>
              <p className='text-baseSm font-medium text-[#514D4C]'>{expenseDataShow.distance || '0'}</p>
            </div>
          </div>
          <div className={dividerClass}></div>
          <div className="flex justify-between flex-col mt-2">
            <div className="flex flex-row w-full justify-between">
              <p className='text-baseMd font-semibold text-[#322F2F]'>Fare</p>
              <p>
                {' '}
                ₹ <span className='text-baseMd font-semibold text-[#322F2F]'>{expenseDataShow.fare || '0'}</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyExpenseList;
